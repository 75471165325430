import {defineRule} from 'vee-validate'
import {email, required, min, max} from '@vee-validate/rules'

/**
 * Validates the strength of a password based on several criteria.
 * Used to determine exactly what rule failed when the rule 'password' fails
 */
function validatePassword(value: string | undefined) {
  const SPECIAL_CHAR_REGEX = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
  const UPPERCASE_REGEX = /[A-Z]/
  const LOWERCASE_REGEX = /[a-z]/
  const NUMBER_REGEX = /[0-9]/

  const errorMessage = []

  if (!min(value, {length: 7})) {
    errorMessage.push('at least 7 characters')
  }

  if (value) {
    if (!SPECIAL_CHAR_REGEX.test(value)) {
      errorMessage.push('at least one special character')
    }
    if (!UPPERCASE_REGEX.test(value)) {
      errorMessage.push('at least one uppercase letter')
    }
    if (!LOWERCASE_REGEX.test(value)) {
      errorMessage.push('at least one lowercase letter')
    }
    if (!NUMBER_REGEX.test(value)) {
      errorMessage.push('at least one number')
    }
  }

  if (errorMessage.length) {
    if (errorMessage.length > 1) {
      errorMessage[errorMessage.length - 1] = 'and ' + errorMessage[errorMessage.length - 1]
      return 'Must contain ' + errorMessage.join(', ') + '.'
    } else {
      return 'Must contain ' + errorMessage.join('') + '.'
    }
  }

  return true
}

/**
 * Validates a required field has a value
 */
defineRule('required', (value: string | number | undefined, [fieldName]:string) => {
  const DEFAULT_FIELD_NAME = 'This field'

  if (required(value)) {
    return true
  }

  if (fieldName === undefined) {
    fieldName = DEFAULT_FIELD_NAME
  }

  return `${fieldName} is required.`
})

/**
 * Validates an email address
 */
defineRule('email', (value: string | undefined) => {
  if (email(value) && required(value) && max(value, {length: 200})) {
    return true
  }

  if(value === '')
  {
    return 'Email is required.'
  }

  return 'Please enter a valid email address.'
})

/**
 * Validates the password has:
 * - at least 7 characters
 * - at least one special character
 * - at least one uppercase letter
 * - at least one lowercase letter
 * - at least one number
 */
defineRule('password', (value: string | undefined) => {
  const PW_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/

  if (required(value) &&
        value &&
        min(value, {length: 7}) &&
        max(value, {length: 100}) &&
        PW_REGEX.test(value)) {
    return true
  }
  return validatePassword(value)
})

/**
 * Validates the second password matches the first
 */
defineRule('confirmPassword', (value: string | undefined, [target]: string) => {
  if (required(value) && value === target) {
    return true
  } else if (value === '') {
    return 'Password is required.'
  }

  return 'Passwords must match.'
})

/**
 * Validates names that:
 * - Contain between 2 and 35 characters
 * - Including uppercase and lowercase Latin letters (including accented letters), hyphens, and spaces
 */
defineRule('validName', (value: string | undefined, [fieldName]:string) => {
  const DEFAULT_FIELD_NAME = 'This field'
  const NAME_REGEX = /^[A-ZÀ-Ýa-zà-ÿ\- ]{2,35}$/

  if ((value && NAME_REGEX.test(value)) || !value || (value && !value.length)) {
    return true
  }

  if (fieldName === undefined) {
    fieldName = DEFAULT_FIELD_NAME
  }

  return `${fieldName} is not valid.`
})

/**
 * Validates suffixes that:
 * - Begin with a letter
 * - Contains any of the following in the middle: letters, spaces, commas, periods, and hyphens
 * - Ends with a letter, punctuation, or space
 */
defineRule('validSuffix', (value: string | undefined) => {
  const SUFFIX_REGEX = /^[^\s\d\W][A-ZÀ-ÖØ-ßa-zà-öø-ÿ ,.-]*[^\s\d:-@!/[\]{}~]$/

  if ((value && SUFFIX_REGEX.test(value)) || !value || (value && !value.length)) {
    return true
  }

  return 'Suffix is not valid.'
})

/**
 * Validates a postal code that:
 * - Contains up to 2 strings of any length of numbers
 * - Can have a space or hyphen between the strings
 */
defineRule('validPostalCode', (value: string | undefined) => {
  const POSTAL_CODE_REGEX = /^[0-9a-zA-Z]+[- ]?[0-9a-zA-Z]+$/g

  if (value && min(value, {length: 5}) && POSTAL_CODE_REGEX.test(value)) {
    return true
  } else if (value === '') {
    return 'Postal Code is required.'
  }

  return 'Postal Code is not valid.'
})

/**
 * Validates a phone number that:
 * - Is only numeric characters
 * - Is exactly ten digits long
 */
defineRule('validPhoneNumber', (value: string | undefined) => {

  const PHONE_NUMBER_REGEX = /^[0-9]{10}$/

  if (required(value) && max(value, {length: 10}) && (value && PHONE_NUMBER_REGEX.test(value))) {
    return true
  }

  if(value === '')
  {
    return 'Phone number is required.'
  }

  return 'Phone number should only include numbers and must be 10 digits.'
})

/**
 * Validates a primary accreditation value is not empty or undefined
 */
defineRule('primaryAccreditation', (data: Record<string, string> | undefined) => {
  if(data?.value === '' || data?.value ===  ' ' || data?.value === undefined)
  {
    return 'This field is required.'
  }

  return true
})

/**
 * Validates a secondary accreditation value:
 * - Can only contain alphanumeric, period, space, or ampersand characters
 * - Can be any length greater than 1 character
 */
defineRule('secondaryAccreditation', (data: Record<string, string> | undefined) => {
  const CREDENTIAL_REGEX = /^[0-9a-zA-Z. &]+$/g

  if(data?.value !== undefined || data?.value === ' ')
  {
    if ((data?.value && CREDENTIAL_REGEX.test(data?.value))) {
      return true
    } else {
      return 'Please Select An Option.'
    }
  }

  return true
})