const PATH_LOGGING_ACTION = '/actions/customlogging/custom-logging/log'
const PATH_SESSION_ACTION = '/actions/users/session-info'
const HEADER_ACCEPT = 'application/json'
const HEADER_X_ACCEPTED_WITH = 'XMLHttpRequest'
import axios, { AxiosResponse } from 'axios'

/**
 * Returns the user's craft session data
 * 
 * @returns Object
 */
export const getSessionInfo = async (): Promise<Record<string, string>> => {
  try {
    const response: AxiosResponse<Record<string, string>> = await axios({
      method: 'GET',
      url: PATH_SESSION_ACTION,
      headers: {
        'Accept': HEADER_ACCEPT,
      },
    })

    return response.data
  } catch (error) {
    console.error('Error fetching session info:', error)
    return {}
  }
}

/**
 * Attempts to log a given message and file path to craft's web 
 * log using the custom logging module (modules/customloggin)
 * 
 * Returns true if the message is logged.
 * 
 * @param message The message to be logged
 * @param callingFile The file path that initializes logging
 * @param logLevel The severity of the log -- warning || error
 * @returns boolean
 */
export const log = async (message: string, callingFile: string, logLevel: string | null = 'error'): Promise<boolean> => {
  const logData = { message, origin: callingFile, level: logLevel }
  let result = false

  try {
    const session = await getSessionInfo()
    const response: AxiosResponse<{ success: boolean; error?: string }> = await axios({
      method: 'POST',
      url: PATH_LOGGING_ACTION,
      headers: {
        'Accept': HEADER_ACCEPT,
        'X-CSRF-Token': session.csrfTokenValue,
        'X-Requested-With': HEADER_X_ACCEPTED_WITH,
      },
      data: JSON.stringify(logData),
    })

    if (response.data.success) {
      result = true
    } else {
      console.error(response.data.error)
    }
  } catch (error) {
    console.error('Error logging message:', error)
  }

  return result
}

export default log
