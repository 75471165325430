import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {del, get, set} from 'idb-keyval';

export interface CacheConfig {
	expirationInSeconds: number,
	key: string
}

interface CacheValue<T> {
	data: T,
	expiration: number
}

interface RequestUrlCallbackMap {
	dataHashCode: number,
	callbacks: ((data: any) => void)[]
}

export class AxiosRequest {
	static requests: RequestUrlCallbackMap[] = [];

	static async cacheableRequest<T = any>(config: AxiosRequestConfig, cache: CacheConfig): Promise<T> {
		const cacheData: CacheValue<T> | undefined = await get(cache.key)
		if (cacheData !== undefined) {
			if (cacheData.expiration > Date.now()) {
				return cacheData.data;
			} else {
				await del(cache.key)
			}
		}

		const {data}: AxiosResponse<T> = await axios(config);
		const cacheValue: CacheValue<T> = {
			data: data,
			expiration: Date.now() + (cache.expirationInSeconds * 1000), // .now returns milliseconds
		}
		await set(cache.key, cacheValue)
		return data
	}

	/**
	 * This is meant for requests such as getCart where mutliple are sent at once all requesting the same data
	 * This is a FIFO queue that groups all requests with the same parameters into buckets and returns a response to all
	 * of those requests.
	 * This doesn't queue requests that are separate URLS (e.g. a request to /a and /b will both be sent with no queueing)
	 *
	 * @param config
	 * @param cache
	 * @param callback
	 */
	static async queuedCacheableRequest<T = any>(config: AxiosRequestConfig, cache: CacheConfig, callback: (data: T) => void): Promise<void> {
		let sendRequest = false
		let foundUrl = false;
		const dataHashCode = this.hashCode(JSON.stringify(config.data));
		for (const request of this.requests) {
			if (request.dataHashCode == dataHashCode) {
				// If the callback queue is empty for this, make sure we send the request
				if (request.callbacks.length == 0) {
					sendRequest = true
				}
				request.callbacks.push(callback)
				foundUrl = true
				break
			}
		}
		if (!foundUrl) {
			this.requests.push({
				dataHashCode: dataHashCode,
				callbacks: [callback],
			});
			sendRequest = true;
		}

		if (sendRequest) {
			const data = await this.cacheableRequest(config, cache);

			// Re-iterate over this since this.requests is static and could have been changed by a separate thread
			for (const request of this.requests) {
				if (request.dataHashCode == dataHashCode) {
					for (const queuedCallback of request.callbacks) {
						queuedCallback(data);
					}
					request.callbacks = []
					break
				}
			}
		}
	}

	private static hashCode(str: string): number {
		let hash = 0;
		for (let i = 0, len = str.length; i < len; i++) {
			let chr = str.charCodeAt(i);
			hash = (hash << 5) - hash + chr;
			hash |= 0; // Convert to 32bit integer
		}
		return hash;
	}
}