import axios, { AxiosResponse } from 'axios'
import gql from '../../utils/gql'
import getStoreName from '../../utils/getStoreName'

interface SendPasswordResetEmailResponse {
  data: {
    requestPasswordResetEmail: boolean | null
  }
  errors?: [{ message: string }]
}

export const sendPasswordResetEmail = gql`
  mutation($email: String!) {
    requestPasswordResetEmail(email: $email)
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const passwordResetEmail = async (email: string): Promise<boolean> => {

  const { data }: AxiosResponse<SendPasswordResetEmailResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    },
    data: {
      query: sendPasswordResetEmail,
      variables: {
        email
      },
    },
  })

  if (data.errors) {
    return false
  }
  return true
}
