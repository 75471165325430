import { createEmptyCartForLoggedInUser, getCustomerCartForLoggedInUser } from '../magento-api-library/cart/createEmptyCart'
import { placeOrderHelper } from '../magento-api-library/cart/placeOrderHelper'
import { addProductsToCart, addBundledProductsToCart } from '../magento-api-library/cart/addProductsToCart'
import { placeOrder } from '../magento-api-library/cart/placeOrder'
import { removeProductFromCart } from '../magento-api-library/cart/removeProductFromCart'
import Cart from '../magento-api-library/types/Cart'


export const getCartId = async (): Promise<string | null> => {
  let cartId = localStorage.getItem('cartId')
  if (!cartId) {
    cartId = await createEmptyCartForLoggedInUser()
    if (cartId != null) {
      localStorage.setItem('cartId', cartId)
    }
  }
  await emptyCart()
  return cartId
}

export const getCustomerCart = async (): Promise<Cart | null> => {
  const cart = await getCustomerCartForLoggedInUser()
  await emptyCart()
  return cart
}

export const cartSubmit = async (cartItems: any, returnUrl?: any): Promise<string | null> => {
  const isCartPrepped = (localStorage.getItem('isCartPrepped') === 'true')
  const cartObject = localStorage.getItem('cart')
  let cartId, order = null
  let doesItemExistInCart = false
  let sessionCart = null

  if (cartObject !== null) {
    sessionCart = localStorage.getItem('cart') ? JSON.parse(cartObject.toString()) : null
  }

  // check if the item exists in cart
  if (sessionCart !== null) {
    cartId = sessionCart.id
    if (sessionCart.items.length > 0) {
      doesItemExistInCart = sessionCart.items.some((item: any) => {
        return item.product.sku === cartItems[0].sku
      })
    }
  }

  if (!doesItemExistInCart) {
    if (!cartId && !isCartPrepped) {
      await placeOrderHelper()
      cartId = localStorage.getItem('cartId')
    }

    if (sessionCart?.selected_payment_method === undefined || sessionCart?.selected_payment_method?.code === undefined) {
      await placeOrderHelper()
    }
    
    sessionCart = await addProductsToCart(cartId, cartItems)
  } 

  if (sessionCart && sessionCart.items.length > 0) {
    order = await placeOrder(sessionCart.id)
    resetCart()
    return order
  }

  return null
}

export const cartSubmitBundledProduct = async (cartItems: any, returnUrl?: any): Promise<string | null> => {

  const isCartPrepped = (localStorage.getItem('isCartPrepped') === "true")
  const cartObject = localStorage.getItem('cart')

  let cartId, order = null
  let doesItemExistInCart = false
  let sessionCart = null

  if (cartObject !== null) {
    sessionCart = localStorage.getItem('cart') ? JSON.parse(cartObject.toString()) : null
  }

  // check if the item exists in cart
  if (sessionCart !== null) {
    cartId = sessionCart.id
    if (sessionCart.items.length > 0) {
      doesItemExistInCart = sessionCart.items.some((item: any) => {
        if(item !== null)
          return item.product.sku === cartItems[0].sku
      })
    }
  }

  if(!doesItemExistInCart) {
    if (!cartId && !isCartPrepped) {
      await placeOrderHelper()
      cartId = localStorage.getItem('cartId')
    }
  } else {
    await emptyCart()
  }

  if (sessionCart?.selected_payment_method === undefined || sessionCart?.selected_payment_method?.code === undefined) {
    await placeOrderHelper()
  }

  sessionCart = await addBundledProductsToCart(cartId, cartItems)

  if (sessionCart && sessionCart.items.length > 0) {
    order = await placeOrder(sessionCart.id)
    resetCart()
    return order
  }
  
  return null
}

export const emptyCart = async (): Promise<void> => {
  // ensuring the cart is really empty to avoid exceptions
  const cartObject = localStorage.getItem('cart')
  let sessionCart = null

  if (cartObject !== null) {
    sessionCart = localStorage.getItem('cart') ? JSON.parse(cartObject.toString()) : null
  }

  if (sessionCart !== null) {
    if (sessionCart.items.length > 0) {
      for (const item of sessionCart.items) {
        if (item !== null) {
          await removeProductFromCart(sessionCart.id, item.uid)
        }
      }
    }
  }
}

export const resetCart = (): void => {
  localStorage.removeItem('cartId')
  localStorage.removeItem('cart')
  localStorage.removeItem('isCartPrepped')
}

