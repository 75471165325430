import gql from '../../utils/gql'
import Country from '../types/Country'
import getStoreName from '../../utils/getStoreName'
import {AxiosRequest} from '../../utils/request';

interface GetCountriesResponse {
  data: {
    countries: Country[]
  }
}

export const getCountriesQuery = gql`
  query {
    countries {
      id
      full_name_english
      two_letter_abbreviation
      available_regions {
        id
        code
        name
      }
    }
  }
`

export const getCountries = async (): Promise<Country[]> => {
  return new Promise<Country[]>(async (resolve, reject) => {
    await AxiosRequest.queuedCacheableRequest({
      method: 'POST',
      url: process.env.MIX_MAGENTO_GRAPHQL,
      headers: {
        'Content-Type': 'application/json',
        Store: getStoreName(),
      },
      data: {
        query: getCountriesQuery,
      },
    }, {key: 'getCountries', expirationInSeconds: 3600}, (data: GetCountriesResponse) => {
      data.data.countries.sort( (a, b) => (a.full_name_english > b.full_name_english) ? 1 : -1 )
      resolve(data.data.countries)
    })
  });
}
