import axios, { AxiosResponse } from 'axios'

import gql from '../../utils/gql'
import getStoreName from '../../utils/getStoreName'

interface LoginResponse {
  data: {
    generateCustomerToken: {
      token: string | null
    }
  }
  errors?: [{ message: string }]
}
interface JWTLoginResponse {
  data: {
    generateCustomerTokenFromJwt: {
      token: string | null
      urlRedirect: string
    }
  }
  errors?: [{ message: string }]
}

export const loginMutation = gql`
  mutation($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      token
    }
  }
`

export const loginWithJwtMutation = gql`
  mutation($jwtAuthToken: String!) {
    generateCustomerTokenFromJwt(jwtAuthToken: $jwtAuthToken) {
      token
      urlRedirect
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const login = async (email: string, password: string): Promise<boolean> => {
  const { data }: AxiosResponse<LoginResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    },
    data: {
      query: loginMutation,
      variables: {
        email,
        password,
      },
    },
  })

  return processLoginResponse(data)
}

export const loginWithJWTToken = async (jwtAuthToken: string): Promise<boolean | string> => {
  const { data }: AxiosResponse<JWTLoginResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    },
    data: {
      query: loginWithJwtMutation,
      variables: {
        jwtAuthToken,
      },
    },
  })

  return processJWTLoginResponse(data)
}

function processLoginResponse(data: LoginResponse): boolean {
  if (data.errors) {
    return false
  }

  if (data.data.generateCustomerToken.token) {
    // 8 hours from the current time
    const expirationTime = new Date(Date.now() + 28800 * 1000).toString()

    const userSession = {
      token: data.data.generateCustomerToken.token,
      expiration: expirationTime,
    }

    localStorage.setItem('authToken', JSON.stringify(userSession))

    return true
  }

  return false
}

function processJWTLoginResponse(data: JWTLoginResponse): boolean | string {
  if (data.errors) {
    return false
  }

  if (data.data.generateCustomerTokenFromJwt.token) {
    // 2 hours from the current time
    const expirationTime = new Date(Date.now() + 7200 * 1000).toUTCString()

    const userSession = {
      token: data.data.generateCustomerTokenFromJwt.token,
      expiration: expirationTime,
    }

    localStorage.setItem('authToken', JSON.stringify(userSession))

    return (data.data.generateCustomerTokenFromJwt.urlRedirect !== '') ? data.data.generateCustomerTokenFromJwt.urlRedirect : true
  }

  return false
}
