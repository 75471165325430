import axios, { AxiosResponse } from 'axios'
import gql from '../../utils/gql'
interface PasswordResetResponse {
  data: {
    resetPassword: boolean
  }
  errors?: [{ message: string }]
}

export const updatePasswordMutation = gql`
  mutation($email: String!, $resetPasswordToken: String!, $newPassword: String!) {
    resetPassword(email: $email, resetPasswordToken: $resetPasswordToken, newPassword: $newPassword)
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const passwordReset = async (
  email: string,
  resetPasswordToken: string,
  newPassword: string
): Promise<boolean | string> => {
  const { data }: AxiosResponse<PasswordResetResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: updatePasswordMutation,
      variables: {
        email,
        resetPasswordToken,
        newPassword
      },
    },
  })

  if (data.errors) {
    return data.errors[0].message
  }

  return true
}
