import { ref } from 'vue'
import { log } from './logging'

interface recaptchaResponse {
  success: boolean, 
  score: number, 
  challenge: boolean, 
  message: string
}

const RECAPTCHA_V2_SCRIPT = 'https://www.google.com/recaptcha/api.js?render=explicit'

export const RECAPTCHA_V2_SITE_KEY = process.env.MIX_RECAPTCHA_V2_KEY
export const recaptchaChallenge = ref(false)

/**
 * Returns Craft's Cross Site Request Forgery (CSRF) token.
 * This token is required to submit form data to a Craft controller
 */
const getCsrfToken = (): string | null | undefined => {
  const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')

  if (!token) {
    log('CSRF token was not found in the meta tags. Unable to submit form data to the recaptcha controller.', 'src/global/js/utils/recaptcha.ts')
  }

  return token
}

/**
 * Validate the user using recaptcha V3
 * 
 */
export const validateRecaptchaV3 = async (token: string): Promise<recaptchaResponse> => {
  const formData = new FormData
  const csrfToken = getCsrfToken()

  let response: Response
  let responseJSON: { 
    success:boolean, 
    score: number,
    challenge: boolean,
    message: string 
  }

  // If the csrf token exists, attempt to validate the user with recaptcha
  // csrf token is required by craft to submit the form to the recaptcha module controller
  if (csrfToken) {
    formData.append('recaptchaToken', token) 
    formData.append('CRAFT_CSRF_TOKEN', csrfToken)

    response = await fetch('/actions/recaptcha/verify-recaptcha', {
      method: 'POST',
      body: formData,
    })
  } else {
    // The csrf token was not found (unable to verify user)
    response = new Response(JSON.stringify({ success: false, score: 0, challenge: false, message: 'CSRF token not found' }), {
      status: 200,
      headers: { 'Content-type': 'application/json' }
    });
  }

  responseJSON = await response.json()

  // User is validated with Recaptcha V3 but the human confidence score is low, challenge the user with recaptcha V2 (checkbox / puzzle)
  if(responseJSON['success'] && responseJSON['challenge']) {
    recaptchaChallenge.value = true
  }

  return responseJSON
}

/**
 * Adds recaptcha v2 script to the page's head element  
 */
export const loadRecaptchaV2 = ():void => {
  const script = document.createElement('script')
  script.src = RECAPTCHA_V2_SCRIPT
  script.async = true
  script.defer = true
  document.head.appendChild(script)
}