import axios, { AxiosResponse } from 'axios'
import type TranscriptItem from '../magento-api-library/types/TranscriptItem'

export type webhookResponse = {
  items: TranscriptItem[] | []
}
export const getN8nTranscripts = async (
  queryParams: {[key: string]: string},
): Promise<TranscriptItem[] | null> => {
  let endpointUrl = `${process.env.MIX_N8N_HOST}${process.env.MIX_N8N_RLP_GETTRANSCRIPTS_URI}`
  let query = ''
  Object.keys(queryParams).forEach(key => query += `&${key}=${queryParams[key] ?? ''}`)
  query = query.replace('&','?')
  endpointUrl += query ?? ''

  const headers = {
    'Content-Type': 'text/plain',
  }
  const {data}: AxiosResponse<webhookResponse> = await axios({
    method: 'GET',
    url: endpointUrl,
    headers: headers,
  })
  if (!data.items?.length) {
    return null
  } else {
    return data.items
  }
}
