import axios from 'axios'

const getRLPSSOToken = async (
  storeview: string,
  authToken: string,
  url?: string,
): Promise<string | null> => {
  try {
    const query = `{
                        fetchRLPDashboardToken(
							url: "${url}")
						{
							ssotoken
						}
					}`
    const result = await axios({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Store: `${storeview}`,
      },
      url: process.env.MIX_MAGENTO_GRAPHQL,
      data: {
        query: query,
      },
    })

    if (result.data.data.fetchRLPDashboardToken?.ssotoken !== undefined) {
      return result.data.data.fetchRLPDashboardToken.ssotoken
    }
  } catch (error) {
    console.log(error)
  }
  return null
}

export default getRLPSSOToken